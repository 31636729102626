import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ManagementPanelImage from "../assets/img/icons/management_panel_icon.png";
import ManagementPanelImageGreen from "../assets/img/icons/management_panel_icon_green.png";
import ViewDataImage from "../assets/img/icons/view_data_icon.png";
import ViewDataImageGreen from "../assets/img/icons/view_data_icon_green.png";
import AnalyticsImage from "../assets/img/icons/Miweigh_Analytics_icon_balanced.png";
import AnalyticsImageGreen from "../assets/img/icons/Miweigh_Analytics_icon_balanced_green.png";
import CurrentStatus from "../assets/img/icons/Current_status_MW.png";
import CurrentStatusImageGreen from "../assets/img/icons/Current_status_MW_Green.png";
import OperatorPerformance from "../assets/img/icons/op.png";
import OperatorPerformanceGreen from "../assets/img/icons/op_green.png";
import Tutorials from "../assets/img/icons/tutorials.png";
import TutorialsGreen from "../assets/img/icons/tutorials-green.png";
import Mining from "../assets/img/icons/mw_mining.png";
import MiningGreen from "../assets/img/icons/mw_mining_green.png";
import GaAnalysis from "../assets/img/icons/giveaway-analysis.png";
import ReportsGreen from "../assets/img/icons/management_panel_icon_green.png";
import Reports from "../assets/img/icons/management_panel_icon.png";
import GaAnalysisGreen from "../assets/img/icons/giveaway-analysis-green.png";
import { useAuth } from "contexts/AuthContext";

const AdminSelectionPage = () => {
  const history = useHistory();
  const { user } = useAuth();

  const [mpImage, setMpImage] = useState(ManagementPanelImage);
  const [vwImage, setVwImage] = useState(ViewDataImage);
  const [abImage, setAbImage] = useState(AnalyticsImage);
  const [csImage, setCsImage] = useState(CurrentStatus);
  const [opImage, setOpImage] = useState(OperatorPerformance);
  const [tutorialsImage, setTutorialsImage] = useState(Tutorials);
  const [miningImage, setMiningImage] = useState(Mining);
  const [gaAnalysisImage, setGaAnalysisImage] = useState(GaAnalysis);
  const [reportsImage, setReportsImage] = useState(Reports)

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      style={{ minHeight: "65vh" }}
    >
      {user.role === "user" || user.role === "engineer" ? null : (
        <Grid
          item
          xs={11}
          md={5}
          className="btn m-2 btn-custom-hover neumorphic"
          // style={{ borderRadius: "30px 0px 0px 30px", backgroundColor: "white" }}
          onClick={() => history.push("/admin/panel")}
          onMouseEnter={() => setMpImage(ManagementPanelImageGreen)}
          onMouseLeave={() => setMpImage(ManagementPanelImage)}
        >
          <img className="m-3" src={mpImage} width={150} alt="" />
          <div className="h1 text-white text-hover">Management Panel</div>
        </Grid>
      )}
      <Grid
        item
        xs={11}
        md={5}
        className="btn m-2 btn-custom-hover neumorphic"
        // style={{ borderRadius: "0px 30px 30px 0px", backgroundColor: "#a7ce39" }}
        onClick={() => history.push("/admin/view_data")}
        onMouseEnter={() => setVwImage(ViewDataImageGreen)}
        onMouseLeave={() => setVwImage(ViewDataImage)}
      >
        <img className="m-3" src={vwImage} width={150} alt="" />
        <div className="h1 text-white text-hover">MiWeigh Dashboard</div>
      </Grid>
      <Grid
        item
        xs={11}
        md={5}
        className="btn m-2 btn-custom-hover neumorphic"
        // style={{ borderRadius: "30px 0px 0px 30px", backgroundColor: "white" }}
        onClick={() => history.push("/admin/analytics")}
        onMouseEnter={() => setAbImage(AnalyticsImageGreen)}
        onMouseLeave={() => setAbImage(AnalyticsImage)}
      >
        <img className="m-3" src={abImage} width={150} alt="" />
        <div className="h1 text-white text-hover">MiWeigh Analytics</div>
      </Grid>
      <Grid
        item
        xs={11}
        md={5}
        className="btn m-2 btn-custom-hover neumorphic"
        // style={{ borderRadius: "30px 0px 0px 30px", backgroundColor: "white" }}
        onClick={() => history.push("/admin/inProgress")}
        onMouseEnter={() => setCsImage(CurrentStatusImageGreen)}
        onMouseLeave={() => setCsImage(CurrentStatus)}
      >
        <img className="m-3" src={csImage} width={150} alt="" />
        <div className="h1 text-white text-hover">Current Status</div>
      </Grid>
      <Grid
        item
        xs={11}
        md={5}
        className="btn m-2 btn-custom-hover neumorphic"
        // style={{ borderRadius: "30px 0px 0px 30px", backgroundColor: "white" }}
        onClick={() => history.push("/admin/operatorPerformance")}
        onMouseEnter={() => setOpImage(OperatorPerformanceGreen)}
        onMouseLeave={() => setOpImage(OperatorPerformance)}
      >
        <img className="m-3" src={opImage} width={150} alt="" />
        <div className="h1 text-white text-hover">Operator Performance</div>
      </Grid>
      <Grid
        item
        xs={11}
        md={5}
        className="btn m-2 btn-custom-hover neumorphic"
        // style={{ borderRadius: "30px 0px 0px 30px", backgroundColor: "white" }}
        onClick={() => history.push("/admin/tutorials")}
        onMouseEnter={() => setTutorialsImage(TutorialsGreen)}
        onMouseLeave={() => setTutorialsImage(Tutorials)}
      >
        <img className="m-3" src={tutorialsImage} width={200} alt="" />
        <div className="h1 text-white text-hover">Tutorials</div>
      </Grid>
      <Grid
        item
        xs={11}
        md={5}
        className="btn m-2 btn-custom-hover neumorphic"
        // style={{ borderRadius: "30px 0px 0px 30px", backgroundColor: "white" }}
        onClick={() => history.push("/admin/ga_analysis")}
        onMouseEnter={() => setGaAnalysisImage(GaAnalysisGreen)}
        onMouseLeave={() => setGaAnalysisImage(GaAnalysis)}
      >
        <img className="m-3" src={gaAnalysisImage} width={150} alt="" />
        <div className="h1 text-white text-hover">Giveaway Analysis</div>
      </Grid>
      {(user.role === "admin" || user.subscriptionLabel === "premium") && (
        //user.role !== "engineer" &&
        <Grid
          item
          xs={11}
          md={5}
          className="btn m-2 btn-custom-hover neumorphic"
          // style={{ borderRadius: "30px 0px 0px 30px", backgroundColor: "white" }}
          onClick={() => history.push("/admin/mining")}
          onMouseEnter={() => setMiningImage(MiningGreen)}
          onMouseLeave={() => setMiningImage(Mining)}
        >
          <img className="m-3" src={miningImage} width={150} alt="" />
          <div className="h1 text-white text-hover">Recipe Analysis</div>
        </Grid>
      )}
      <Grid
        item
        xs={11}
        md={5}
        className="btn m-2 btn-custom-hover neumorphic"
        // style={{ borderRadius: "30px 0px 0px 30px", backgroundColor: "white" }}
        onClick={() => history.push("/admin/reports")}
        onMouseEnter={() => setReportsImage(ReportsGreen)}
        onMouseLeave={() => setReportsImage(Reports)}
      >
        <img className="m-3" src={reportsImage} width={150} alt="" />
        <div className="h1 text-white text-hover">Reports</div>
      </Grid>
    </Grid>
  );
};

export default AdminSelectionPage;
