import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import DataCardTemplate from "./DataCardTemplate";
import PacksProducedImage from "../../../assets/img/icons/packs_produced_icon_w.png";
import GiveAwayPercentage from "../../../assets/img/icons/Giveaway-percentage-icon.png";
import WeightProducedIcon from "../../../assets/img/icons/weight-produced-icon.png";
import AverageGiveAwayImage from "../../../assets/img/icons/average_giveaway_icon.png";

function Index({ clientId, filtersData }) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    totalGA: 0,
    totalTonnage: 0,
    gaPercentage: 0,
    totalPacks: 0,
  });

  const getCardsData = () => {
    axios
      .get(`${baseUrl}/api/gaAnalysis/cardsData`, {
        params: {
          ...filtersData,
          clientId,
        },
        headers: authHeader(),
      })
      .then((res) => {
        setData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setData([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getCardsData();
  }, [clientId, filtersData]);

  return (
    <Row className="my-3">
      <Col style={{ maxWidth: "unset", flexGrow: 1 }} xs="12" md="2">
        <DataCardTemplate
          isLoading={isLoading}
          title="Total Giveaway"
          value={data.totalGA ? Math.round(data.totalGA / 1000) || 0 : 0}
          cardImage={AverageGiveAwayImage}
          toolTipData={{
            id: "total-giveaway",
            message: "Total giveaway (KG)",
          }}
        />
      </Col>
      <Col style={{ maxWidth: "unset", flexGrow: 1 }} xs="12" md="2">
        <DataCardTemplate
          isLoading={isLoading}
          title="Total Tonnage"
          value={data.totalTonnage ? Math.round(data.totalTonnage) || 0 : 0}
          cardImage={WeightProducedIcon}
          toolTipData={{
            id: "total-tonnage",
            message: "Total tonnage (KG)",
          }}
        />
      </Col>
      <Col style={{ maxWidth: "unset", flexGrow: 1 }} xs="12" md="2">
        <DataCardTemplate
          isLoading={isLoading}
          title="GA percentage"
          value={data.gaPercentage ? data.gaPercentage.toFixed(2) || 0 : 0}
          cardImage={GiveAwayPercentage}
          toolTipData={{
            id: "ga-percentage",
            message: "Give away percentage",
          }}
        />
      </Col>
      <Col style={{ maxWidth: "unset", flexGrow: 1 }} xs="12" md="2">
        <DataCardTemplate
          isLoading={isLoading}
          title="Total Packs"
          value={data.totalPacks ? data.totalPacks || 0 : 0}
          cardImage={PacksProducedImage}
          toolTipData={{
            id: "total-packs",
            message: "Total Packs",
          }}
        />
      </Col>
    </Row>
  );
}

export default Index;
