import React, { useContext, useState } from "react";
import { Grid } from "@material-ui/core";
import Filters from "./Filters.jsx";
import { Container } from "reactstrap";
import DataCards from "./DataCards/index.jsx";
import DataCharts from "./DataCharts/index.jsx";
import GiveawayTableData from "./GiveawayTableData.jsx";
import { DataContext } from "layouts/Admin.js";

function Index({ clientId, clientSubscription }) {
  const { filtersData, setFiltersData, timeRange, setTimeRange } = useContext(DataContext)

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 7);

  const handleApplyFilters = (filterData) => {
    setFiltersData({ ...filterData });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    // className="px-5"
    >
      <Container className="bg-transparent" fluid>
        <Filters
          filtersData={filtersData}
          handleApplyFilters={handleApplyFilters}
          clientId={clientId}
          clientSubscription={clientSubscription}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
        />
        <DataCards
          clientId={clientId}
          filtersData={filtersData}
          clientSubscription={clientSubscription}
          timeRange={timeRange}
        />

        <GiveawayTableData
          clientId={clientId}
          clientSubscription={clientSubscription}
          filtersData={filtersData}
          timeRange={timeRange}
        />

        <DataCharts
          clientId={clientId}
          filtersData={filtersData}
          clientSubscription={clientSubscription}
          timeRange={timeRange}
        />
      </Container>
    </Grid>
  );
}

export default Index;
