import { Card, CardBody, Container, Row, Col } from "reactstrap";
import Cost2 from "./Cost2";
import Lines from "./lines";
import Packs from "./Packs";
import KPI from "./Kpi";
import Header from "components/Headers/Header";
import DataTable from "views/TableContainer";

const ChartIndex = ({
  filtersData,
  setFiltersData,
  clientId,
  clientSubscription,
}) => {
  return (
    <>
      <div className="header bg-transparent py-0 mt-4">
        <Container fluid>
          <div className="header-body">
            <Row>
              {filtersData.line &&
              filtersData.line !== "All" &&
              !filtersData.lineExclude ? null : (
                <Col
                  xs="12"
                  md="6"
                  xl="4"
                  // xl={window.innerWidth > 1880 ? "3" : "4"}
                  className="mb-4"
                >
                  <Card className="card-stats recipe-height-custom">
                    <CardBody className="p-0">
                      <Lines
                        filtersData={filtersData}
                        setFiltersData={setFiltersData}
                        clientId={clientId}
                      />
                    </CardBody>
                  </Card>
                </Col>
              )}

              {/* <Col
                xs="12"
                md="8"
                xl={window.innerWidth > 1880 ? "6" : "8"}
                className="mb-4"
              >
                <Card className="card-stats" style={{ height: "100%" }}>
                  <CardBody className="p-0">
                    <AvgSpeed
                      title={"Average Speed"}
                      filtersData={filtersData}
                      setFiltersData={setFiltersData}
                      clientId={clientId}
                    />
                  </CardBody>
                </Card>
              </Col> */}
              {/* <Col
                xs={
                  filtersData.line && filtersData.line !== "All" ? "12" : "12"
                }
                md={filtersData.line && filtersData.line !== "All" ? "4" : "6"}
                xl="4"
                // xl={
                //   filtersData.line && filtersData.line !== "All"
                //     ? "4"
                //     : window.innerWidth > 1880
                //     ? "3"
                //     : "12"
                // }
                className="mb-3"
              > */}
              <Header filtersData={filtersData} clientId={clientId} />
              {/* </Col> */}

              {/* <Col
                xs={
                  filtersData.line && filtersData.line !== "All" ? "12" : "12"
                }
                md={filtersData.line && filtersData.line !== "All" ? "4" : "6"}
                xl="4"
                // xl={
                //   filtersData.line && filtersData.line !== "All"
                //     ? "4"
                //     : window.innerWidth > 1880
                //     ? "3"
                //     : "12"
                // }
                className="mb-3"
              >
                <DataCard filtersData={filtersData} clientId={clientId} />
              </Col> */}
            </Row>
            <DataTable
              filtersData={filtersData}
              setFiltersData={setFiltersData}
              clientId={clientId}
              clientSubscription={clientSubscription}
            />
            <Row>
              {/* <Col
                xs={
                  filtersData.line && filtersData.line !== "All" ? "12" : "12"
                }
                md={filtersData.line && filtersData.line !== "All" ? "4" : "6"}
                xl="3"
                // xl={
                //   filtersData.line && filtersData.line !== "All"
                //     ? "4"
                //     : window.innerWidth > 1880
                //     ? "3"
                //     : "12"
                // }
                className="mb-3"
              >
                <Header filtersData={filtersData} clientId={clientId} />
              </Col> */}
              <Col xs="12" md="6" xl="4" className="mb-4">
                <Card className="card-stats" style={{ height: "100%" }}>
                  <CardBody className="p-0">
                    <Packs
                      title={"Packs Produced"}
                      filtersData={filtersData}
                      setFiltersData={setFiltersData}
                      clientId={clientId}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col xs="12" md="6" xl="4" className="mb-4">
                <Card className="card-stats" style={{ height: "100%" }}>
                  <CardBody className="p-0">
                    <Cost2
                      title={"Cost Analysis"}
                      labelData={["Labour £", "GA £", "Pack £", "Total £"]}
                      filtersData={filtersData}
                      setFiltersData={setFiltersData}
                      clientId={clientId}
                    />
                  </CardBody>
                </Card>
              </Col>

              {/* <Col xs="12" md="6" className="mb-4">
                <Card className="card-stats" style={{ height: "100%" }}>
                  <CardBody className="p-0">
                    <Cost
                      title={"Labour Cost vs GA Cost"}
                      labelData={["Labour £", "GA £", "Pack £", "Total £"]}
                      filtersData={filtersData}
                      setFiltersData={setFiltersData}
                      clientId={clientId}
                    />
                  </CardBody>
                </Card>
              </Col> */}
              <Col xs="12" md="6" xl="4" className="mb-4">
                <Card className="card-stats" style={{ height: "100%" }}>
                  <CardBody className="p-0">
                    <KPI
                      title={"KPI & Average GA"}
                      filtersData={filtersData}
                      setFiltersData={setFiltersData}
                      clientId={clientId}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ChartIndex;
