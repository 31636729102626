import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Tooltip,
} from "reactstrap";
import TableDataComponent from "./TableDataComponent";
import BarChartsGiveawayPercentage from "./BarChartsGiveawayPercentage";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import { FaInfoCircle } from "react-icons/fa";

function DetailedDataModal({
  isOpen,
  onClose,
  clientId,
  modalData,
  clientSubscription,
}) {
  const [tooltip, setTooltip] = useState({
    gaPercentage: false,
    totalGa: false,
    totalRuntime: false,
    totalPacks: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState(null);
  const [chartTitles, setChartTitles] = useState([]);
  const [chartData, setChartData] = useState({
    recipes: {
      x: [],
      y: [],
    },
    products: { x: [], y: [] },
  });

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/api/gaAnalysis/modalData`, {
        params: {
          date: modalData.day,
          clientId,
        },
        headers: authHeader(),
      })
      .then((res) => {
        console.log("Response ga modal: ", res);
        const data = res.data.result; // Assuming the response data structure as shown

        const transformedData = data.topValues;

        setTableData(transformedData);
        setChartData({
          recipes: data.recipes,
          products: data.products,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data: ", err);
        setIsLoading(false);
      });
  }, [clientId, modalData]);

  const tableColumns = [
    { id: "name", className: "text-center table-data-font", header: "Name" },
    { id: "value", className: "text-center table-data-font", header: "Value" },
  ];

  const cardTitles = [
    {
      name: "Giveaway Percentage",
      tooltip: "Giveaway percentage",
      id: "gaPercentage",
      dataKey: "GA_Percentage",
    },
    {
      name: "Total GA",
      tooltip: "in grams",
      id: "totalGa",
      dataKey: "Total_GA",
    },
    {
      name: "Total Runtime",
      tooltip: "Total Runtime",
      id: "totalRuntime",
      dataKey: "Run_Time",
    },
    {
      name: "Total Packs",
      tooltip: "Total Packs",
      id: "totalPacks",
      dataKey: "Total_Packs",
    },
  ];

  const baseChart = [
    {
      name: "Recipes",
      tooltip: "Recipes according to GA percentage",
      id: "recipesChart",
      dataKey: "recipes",
    },
  ];

  const premiumChart = [
    {
      name: "Products",
      tooltip: "Products according to GA percentage",
      id: "productsChart",
      dataKey: "products",
    },
  ];

  useEffect(() => {
    if (clientSubscription === "premium") {
      setChartTitles([...baseChart, ...premiumChart]);
    } else setChartTitles([...baseChart]);
  }, [clientSubscription]);

  // Reusable function to render Cards
  const renderCard = (item) => {
    return isLoading ? null : (
      <Card key={item.id} className="card-stats h-100 mb-4 p-2">
        <CardHeader
          className="bg-transparent p-0 px-3"
          style={{ color: "#a7ce39" }}
        >
          {item.name}
          <FaInfoCircle id={item.id} fontSize={"18px"} className="ml-2" />
          <Tooltip
            placement={"top"}
            isOpen={tooltip[item.id]}
            target={item.id}
            toggle={() => {
              setTooltip((st) => {
                return {
                  ...st,
                  [item.id]: !st[item.id],
                };
              });
            }}
          >
            {item.tooltip}
          </Tooltip>
        </CardHeader>
        <CardBody className="p-3">
          <Row className="justify-content-center align-items-stretch">
            {Object.entries(tableData[item.dataKey]).map(
              ([keyTop, dataTop]) => (
                <Col
                  xs="12"
                  lg={
                    keyTop === "Top Recipe" && clientSubscription === "premium"
                      ? "5"
                      : clientSubscription === "premium"
                      ? "3"
                      : ""
                  }
                  xl={
                    keyTop === "Top Recipe" && clientSubscription === "premium"
                      ? "4"
                      : ""
                  }
                  key={keyTop}
                  className="p-0 m-1"
                >
                  <TableDataComponent
                    title={keyTop.replace("Rcp_", "")}
                    tableData={dataTop}
                    tableColumns={tableColumns}
                  />
                </Col>
              )
            )}
          </Row>
        </CardBody>
      </Card>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      backdropClassName="modal-backdrop-opacity"
      contentClassName="modal-bg"
      className="modal-width"
    >
      <ModalHeader toggle={onClose}>
        <h2 style={{ color: "#a7ce39" }}>Detailed Giveaway Analysis</h2>
      </ModalHeader>
      {isLoading ? null : (
        <ModalBody>
          {cardTitles.map((item) => renderCard(item))}
          {chartTitles.map((item) => (
            <BarChartsGiveawayPercentage
              data={chartData[item.dataKey]}
              tooltipData={{ id: item.id, message: item.tooltip }}
              title={item.name}
            />
          ))}
        </ModalBody>
      )}
    </Modal>
  );
}

export default DetailedDataModal;
