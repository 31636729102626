import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Progress,
  Spinner,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import Table from "views/shared/Table";
import { TablePagination } from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import deepEqual from "utils/deepequal";
import { Skeleton } from "@mui/material";
import moment from "moment";

function RecentReports({ clientId }) {
  const [filter, setFilter] = useState({
    filtersData: {
      page: 1,
      rowsPerPage: 10,
    },
    clientId,
  });
  const [tableData, setTableData] = useState([]);
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (
      filter.clientId === clientId
    )
      return;
    setTableData("Loading...");
    setFilter({
      filtersData: {
        page: 0,
        rowsPerPage: 10,
      },
      clientId,
    });
  }, [clientId]);



  useEffect(() => {
    setTableData("Loading...");
    axios
      .get(
        `${baseUrl}/api/reports/${filter.filtersData.page}/${filter.filtersData.rowsPerPage}`,
        {
          params: { clientId },
          headers: authHeader(),
        }
      )
      .then((res) => {
        console.log('res.data.reports', res.data.reports)
        setTableData(res.data.reports);
        setCount(res.data.totalCount);
      })
      .catch((err) => {
        console.log(err);
        setTableData([]);
      });
  }, [
    filter,
    filter.filtersData.page,
    filter.filtersData.rowsPerPage,
    clientId,
  ]);

  const tableColumns = [
    {
      id: "report_name",
      title: "Name",
      className: "sort table-data-font",
      onClick: null,
      render: (row) => row.report_name.split('_').join(' ')
    },
    {
      id: "report_from",
      title: "From",
      className: "sort table-data-font",
      onClick: null,
      render: (row) => moment(row.report_from).format('DD/MM/YYYY HH:mm')
    },
    {
      id: "report_to",
      title: "To",
      className: "sort table-data-font",
      onClick: null,
      render: (row) => moment(row.report_to).format('DD/MM/YYYY HH:mm')
    },
    {
      id: "report_type",
      title: "Type",
      className: "sort table-data-font",
      onClick: null,
      render: (row) => row.report_type.charAt(0).toUpperCase() + row.report_type.slice(1)
    },
    {
      id: "s3_url",
      title: "Actions",
      className: "table-data-font",
      onClick: null,
      render: (row) => (
        <div className="flex gap-2">
          <a
            href={row.s3_url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800"
          >
            Download
          </a>
        </div>
      )
    },
    {
      id: "createdAt",
      title: "Downloaded At",
      className: "sort table-data-font",
      onClick: null,
      render: (row) => moment(row.createdAt).format('DD/MM/YYYY HH:mm')
    }
  ];


  const handleChangePage = (event, newPage) => {
    setFilter((st) => ({
      ...st,
      filtersData: {
        ...st.filtersData,
        page: newPage,
      },
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage();
    // setPage(0);
    setFilter((st) => ({
      ...st,
      filtersData: {
        ...st.filtersData,
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      },
    }));
  };

  return (
    <>
      <Container fluid>
        <Row className="">
          {/* <Col lg="12" xl="12"> */}
          <Col xs={12} className="mb-4">
            <Card className="shadow h-100">
              <h2
                className="px-4 pt-4 text-color-mw"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Recent Reports

              </h2>

              <>
                <Table
                  values={[...tableData]}
                  columns={tableColumns}
                  tableClasses={{
                    table:
                      "table align-items-center table-flush table-sm border-bottom text-white table-custom",
                    thead: "",
                    tbody: "list table-td-color",
                  }}
                  isLoading={tableData === "Loading..."}
                  handleSelected
                />

                {tableData === "Loading..." ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 20,
                      marginRight: 20,
                    }}
                  >
                    <Skeleton variant="rectangular" width={150} />
                    <Skeleton variant="rectangular" width={100} />
                    <Skeleton variant="rectangular" width={20} />
                    <Skeleton variant="rectangular" width={20} />
                  </div>
                ) : (
                  <TablePagination
                    component="div"
                    className="text-muted"
                    count={count}
                    page={filter.filtersData.page}
                    onPageChange={handleChangePage}
                    rowsPerPage={filter.filtersData.rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default RecentReports;
