import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner, Card, CardBody } from "reactstrap";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import LineChartGA from "./LineChartGA";
import ProductChartGA from "./ProductChartGA";
import RecipeChartGA from "./RecipeChartGA";

function Index({ clientId, filtersData, clientSubscription }) {
  // const getData = () => {
  //   axios
  //     .get(`${baseUrl}/api/gaAnalysis/chartsData`, {
  //       params: {
  //         ...filtersData,
  //         clientId,
  //       },
  //       headers: authHeader(),
  //     })
  //     .then((res) => {
  //       setData(res.data);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       setData([]);
  //       setIsLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   setIsLoading(true);
  //   getData();
  // }, [clientId, filtersData]);

  return (
    <Row className="my-3">
      <Col
        xs="12"
        md="6"
        xl={clientSubscription === "premium" ? "4" : "6"}
        className="mb-4"
      >
        <LineChartGA clientId={clientId} filtersData={filtersData} />
      </Col>
      {clientSubscription === "premium" && (
        <Col xs="12" md="6" xl="4" className="mb-4">
          <ProductChartGA clientId={clientId} filtersData={filtersData} />
        </Col>
      )}

      <Col
        xs="12"
        md="6"
        xl={clientSubscription === "premium" ? "4" : "6"}
        className="mb-4"
      >
        <RecipeChartGA clientId={clientId} filtersData={filtersData} />
      </Col>
    </Row>
  );
}

export default Index;
