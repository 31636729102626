import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Progress,
  Spinner,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import Table from "views/shared/Table";
import { TablePagination } from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import deepEqual from "utils/deepequal";
import { Skeleton } from "@mui/material";

function OperatorsLinesData({ filtersData, clientId, timeRange, type }) {
  const [filter, setFilter] = useState({
    filtersData: {
      start_date: filtersData.start_date,
      end_date: filtersData.end_date,
      line: filtersData.line,
      page: 0,
      rowsPerPage: 10,
    },
    clientId,
  });
  const [tableData, setTableData] = useState([]);
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);
  const [totalPacks, setTotalPacks] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const getEndDate = (date) => {
    let day = date.getUTCDate();
    let month = date.toLocaleString("en-GB", {
      month: "short",
      timeZone: "UTC",
    });
    let year = date.getUTCFullYear();

    // Format the date string as "11 Sep 2024"
    let formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
  };

  useEffect(() => {
    if (
      deepEqual(
        {
          start_date: filtersData.start_date,
          end_date: filtersData.end_date,
          line: filtersData.line,
        },
        {
          start_date: filter.filtersData.start_date,
          end_date: filter.filtersData.end_date,
          line: filter.filtersData.line,
        }
      ) &&
      filter.clientId === clientId
    )
      return;
    setTableData("Loading...");
    setFilter({
      filtersData: {
        start_date: filtersData.start_date,
        end_date: filtersData.end_date,
        line: filtersData.line,
        page: 0,
        rowsPerPage: 10,
      },
      clientId,
    });
  }, [clientId, filtersData]);

  const handleReport = () => {
    axios
      .post(
        `${baseUrl}/api/reports/operators-data-report`,
        {
          operators: selectedItems.map((el) => el.Op_Name),
        },
        {
          params: { ...filter.filtersData, clientId },
          headers: authHeader(),
        }
      )
      .then((res) => {
        res.data.urls.forEach((url) => {
          console.log("url", url);
          window.open(url, "_blank");
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setTableData("Loading...");
    axios
      .get(
        `${baseUrl}/api/reports/operators-data/${filter.filtersData.page}/${filter.filtersData.rowsPerPage}`,
        {
          params: { ...filter.filtersData, clientId },
          headers: authHeader(),
        }
      )
      .then((res) => {
        setTableData(res.data.operators_data);
        setCount(res.data.pagination.totalRecords);
        setTotalPacks(res.data.totalPacks);
      })
      .catch((err) => {
        console.log(err);
        setTotalPacks(0);
        setTableData([]);
      });
  }, [
    filter,
    filter.filtersData.page,
    filter.filtersData.rowsPerPage,
    clientId,
  ]);

  const handleCheckboxChange = (value) => {
    console.log("clicked");
    console.log("value", value);
    const index = selectedItems.findIndex((item) => item.id === value.id);
    if (index === -1) {
      setSelectedItems([...selectedItems, value]);
    } else {
      setSelectedItems([
        ...selectedItems.slice(0, index),
        ...selectedItems.slice(index + 1),
      ]);
    }
  };

  const tableColumns = [
    {
      id: "checkbox",
      title: "",  // Empty title since we don't want header checkbox
      className: "sort table-data-font",
      onClick: (el) => {
        console.log(el);
      },
      render: (value) => (
        // <input
        //   type="checkbox"
        //   checked={selectedItems.includes(value.id)}  // Assuming your data has an id field
        //   onChange={() => handleCheckboxChange(value.id)}
        //   className="form-check-input"
        // />
        <FormGroup className="mb-4" check>
          <Input
            type="checkbox"
            style={{ cursor: "pointer" }}
            checked={selectedItems.includes(value.id)}  // Assuming your data has an id field
            onChange={(e) => {
              e.stopPropagation();
              handleCheckboxChange(value.id)
            }}
          />
        </FormGroup>
      ),
    },
    {
      id: "actions",
      title: "actions",
      className: "sort table-data-font",
      onClick: null,
      render: (record) => (
        <i
          className="text-danger btn fa fa-trash px-1 py-0 m-0"
          onClick={() => {

          }}
        />
      ),
    },
    {
      id: "date",
      title: "date",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Op_Name",
      title: "Operator",
      className: "sort table-data-font",
      onClick: null,
      render: (value) => <>{value.Op_Name}</>,
    },
    {
      id: "total_packs",
      title: "Packs Produced",
      className: "sort table-data-font",
      onClick: null,
      render: (value) => <>{value.total_packs}</>,
    },
    {
      id: "total_weight",
      title: "Total Weight",
      className: "sort table-data-font",
      onClick: null,
      render: (value) => <>{value.total_weight}</>,
    },
    {
      id: "production_time_mins",
      title: "Production Time (mins)",
      className: "sort table-data-font",
      onClick: null,
      render: (value) => <>{value.production_time_mins}</>,
    },
  ];


  const handleChangePage = (event, newPage) => {
    setFilter((st) => ({
      ...st,
      filtersData: {
        ...st.filtersData,
        page: newPage,
      },
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage();
    // setPage(0);
    setFilter((st) => ({
      ...st,
      filtersData: {
        ...st.filtersData,
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      },
    }));
  };

  return (
    <>
      <Container fluid>
        <Row className="">
          {/* <Col lg="12" xl="12"> */}
          <Col xs={12} className="mb-4">
            <Card className="shadow h-100">
              <h2
                className="px-4 pt-4 text-color-mw"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Operators Data
                {selectedItems.length > 0 &&
                  <Button
                    style={{
                      backgroundColor: "#a7ce39",
                      border: "none",
                      height: "33px",
                      width: "fit-content",
                    }}
                    className="px-4"
                    size="sm"
                    onClick={handleReport}
                  >
                    Get Report for selected
                    <i className="fa fa-plus ml-2" />
                  </Button>
                }
              </h2>

              <>
                <Table
                  values={[...tableData]}
                  columns={tableColumns}
                  tableClasses={{
                    table:
                      "table align-items-center table-flush table-sm border-bottom text-white table-custom",
                    thead: "",
                    tbody: "list table-td-color",
                  }}
                  isLoading={tableData === "Loading..."}
                  handleSelected
                  selected={selectedItems}
                />

                {tableData === "Loading..." ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 20,
                      marginRight: 20,
                    }}
                  >
                    <Skeleton variant="rectangular" width={150} />
                    <Skeleton variant="rectangular" width={100} />
                    <Skeleton variant="rectangular" width={20} />
                    <Skeleton variant="rectangular" width={20} />
                  </div>
                ) : (
                  <TablePagination
                    component="div"
                    className="text-muted"
                    count={count}
                    page={filter.filtersData.page}
                    onPageChange={handleChangePage}
                    rowsPerPage={filter.filtersData.rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default OperatorsLinesData;
