import React from "react";

const TableDataComponent = ({ title, tableData, tableColumns }) => {
  return (
    <div
      className="m-2 p-2 h-100"
      style={{ border: "2px solid #434343", borderRadius: "5px" }}
    >
      <div
        className="text-md text-center mb-2 font-weight-bolder"
        style={{ color: "#a7ce39" }}
      >
        {title}
      </div>
      <table
        className="w-100 table-fixed"
        style={{
          width: "100%",
          color: "#9a9a9a",
          tableLayout: "fixed", // Fixes the table layout
          borderCollapse: "collapse",
        }}
      >
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index} className="hover:bg-gray-100">
              {tableColumns.map((col) => (
                <td
                  key={col.id}
                  className={`p-2 ${col.className}`}
                  style={{
                    // borderBottom: "1px solid #ddd",
                    wordWrap: "break-word", // Prevents overflow
                  }}
                >
                  {row[col.id]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableDataComponent;
