import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import Table from "views/shared/Table";
import { TablePagination } from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import { Skeleton } from "@mui/material";

function LinesData({ filtersData, clientId, title }) {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/api/reports/line-report-data/${page}/${rowsPerPage}`, {
        params: { ...filtersData, clientId },
        headers: authHeader(),
      })
      .then((res) => {
        setTableData(res.data.tableRows);
        setCount(res.data.count);
      })
      .catch((err) => {
        console.error(err);
        setTableData([]);
        setCount(0);
      })
      .finally(() => setIsLoading(false));
  }, [page, rowsPerPage, filtersData, clientId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  console.log("Selected Items: ", selectedItems);

  const handleReport = useCallback(() => {
    console.log("Selected Items ==: ", selectedItems);

    const selectedItemsArray = selectedAll
      ? tableData.map((item) => item.batch_ids)
      : selectedItems.map((item) => item.batch_ids);
    axios
      .get(`${baseUrl}/api/reports/line-report`, {
        params: {
          ...filtersData,
          clientId,
          linePerDayArray: [selectedItemsArray],
        },
        headers: authHeader(),
      })
      .then((res) => {
        // res.data.urls.forEach((url) => {
        //   console.log("url", url);
        //   window.open(url, "_blank");
        // });
        console.log(res);
        if (res.data.s3_url) {
          // Create a hidden anchor element
          const link = document.createElement("a");
          link.href = res.data.s3_url;
          link.setAttribute(
            "download",
            `Line_Report_${new Date().toISOString()}.xlsx`
          );
          link.setAttribute("target", "_blank");

          // Append to document, trigger click, and remove
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [selectedAll, selectedItems, tableData, filtersData, clientId]);

  const handleSelectItems = useCallback(
    (check, item) => {
      if (check) {
        if (item === "all") {
          setSelectedItems([...tableData]);
          setSelectedAll(true);
        } else {
          setSelectedItems((prev) =>
            prev.find((r) => r.id === item.id) ? prev : [...prev, item]
          );
        }
      } else {
        if (item === "all") {
          setSelectedItems([]);
          setSelectedAll(false);
        } else {
          setSelectedItems((prev) => prev.filter((r) => r.id !== item.id));
        }
      }
    },
    [tableData]
  );

  const tableColumns = useMemo(
    () => [
      {
        id: "selectItem",
        title: (
          <FormGroup className="ml-3 mb-4" check>
            <Input
              type="checkbox"
              checked={selectedAll}
              onChange={(e) => handleSelectItems(e.target.checked, "all")}
              style={{ transform: "scale(1.5)" }}
            />
          </FormGroup>
        ),
        className: "sort table-data-font",
        render: (record) => (
          <FormGroup className="mb-4 ml-3" check>
            <Input
              type="checkbox"
              checked={
                selectedAll ||
                selectedItems.some((selected) => selected.id === record.id)
              }
              onChange={(e) => handleSelectItems(e.target.checked, record)}
              style={{ transform: "scale(1.5)" }}
            />
          </FormGroup>
        ),
      },
      { id: "day", title: "Date", className: "sort table-data-font" },
      { id: "Line", title: "Line", className: "sort table-data-font" },
      {
        id: "total_packs",
        title: "Packs Produced",
        className: "sort table-data-font",
      },
      {
        id: "total_weight",
        title: "Total Weight",
        className: "sort table-data-font",
      },
      {
        id: "total_runtime",
        title: "Production Time (mins)",
        className: "sort table-data-font",
      },
      {
        id: "gaPercentage",
        title: "GA Percentage",
        className: "sort custom-padding table-data-font",
        render: (record) =>
          ((record.total_ga / (record.total_weight * 1000)) * 100).toFixed(2),
      },
      {
        id: "kpi",
        title: "KPI",
        className: "sort custom-padding table-data-font",
        render: (record) => (record.kpi / record.total_packs || 1).toFixed(2),
      },
    ],
    [selectedAll, selectedItems, handleSelectItems]
  );

  const tableClasses = useMemo(
    () => ({
      table:
        "table align-items-center table-flush table-sm border-bottom text-white table-custom",
      tbody: "list table-td-color",
    }),
    []
  );

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="mb-4">
          <Card className="shadow h-100">
            <h2 className="px-4 pt-4 text-color-mw d-flex justify-content-between">
              {title}
              {selectedItems.length > 0 && (
                <Button
                  style={{
                    backgroundColor: "#a7ce39",
                    border: "none",
                    height: "33px",
                    width: "fit-content",
                  }}
                  className="px-4"
                  size="sm"
                  onClick={handleReport}
                >
                  Get Report for Selected <i className="fa fa-plus ml-2" />
                </Button>
              )}
            </h2>
            <>
              <Table
                values={tableData}
                columns={tableColumns}
                tableClasses={tableClasses}
                isLoading={isLoading}
                handleSelected={handleSelectItems}
                selected={selectedItems}
              />
              {isLoading ? (
                <div className="d-flex justify-content-end gap-3 mr-3">
                  <Skeleton variant="rectangular" width={150} />
                  <Skeleton variant="rectangular" width={100} />
                </div>
              ) : (
                <TablePagination
                  component="div"
                  className="text-muted"
                  count={count}
                  page={page}
                  onPageChange={(event, newPage) => setPage(newPage)}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={(event) =>
                    setRowsPerPage(parseInt(event.target.value, 10))
                  }
                />
              )}
            </>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default React.memo(LinesData);
