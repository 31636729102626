import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Progress,
  Spinner,
  CardBody,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import Table from "views/shared/Table";
import { TablePagination } from "@material-ui/core";
import InfoModal from "views/shared/InfoModal";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import Recipes from "../charts/Recipes";
import deepEqual from "utils/deepequal";
import { Skeleton } from "@mui/material";

function BatchesData({
  filtersData,
  setFiltersData,
  clientId,
  clientSubscription,
  title = 'Production Data'
}) {
  const [filter, setFilter] = useState({
    filtersData: { ...filtersData },
    page: 0,
    rowsPerPage: 10,
    clientId,
  });
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);
  const [isGeneratingReport1, setIsGeneratingReport1] = useState(false);
  const [isGeneratingReport2, setIsGeneratingReport2] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    console.log('selectedItems', selectedItems)
  }, [selectedItems])


  const handleCheckboxChange = (value) => {
    console.log('value', value)
    console.log('selectedItems here', selectedItems)
    const index = selectedItems.findIndex((item) => item.Batch_ID === value.Batch_ID);
    console.log('index', index)
    if (index === -1) {
      setSelectedItems([...selectedItems, value]);
    } else {
      setSelectedItems([
        ...selectedItems.slice(0, index),
        ...selectedItems.slice(index + 1),
      ]);
    }
  };

  useEffect(() => {
    if (
      deepEqual(filtersData, filter.filtersData) &&
      filter.clientId === clientId &&
      filter.page === page &&
      filter.rowsPerPage === rowsPerPage
    )
      return;
    setTableData("Loading...");
    setFilter({ filtersData: { ...filtersData }, clientId, page, rowsPerPage });
    axios
      .get(`${baseUrl}/api/dashboard/production_data/${page}/${rowsPerPage}`, {
        params: { ...filtersData, clientId },
        headers: authHeader(),
      })
      .then((res) => {
        console.log("Actual Data from dB: ", res.data.formattedEndData);
        setTableData(res.data.formattedEndData);
        setCount(res.data.count);
      })
      .catch((err) => {
        console.log(err);
        setTableData([]);
      });
  }, [page, rowsPerPage, clientId, filtersData]);

  const baseColumns = [
    {
      id: "checkbox",
      title: "", // Empty title since we don't want header checkbox
      className: "sort table-data-font",
      onClick: (el) => {
        console.log(el);
      },
      render: (value) => {
        console.log('value', value)
        console.log('selectedItems', selectedItems)
        console.log('checled', !!selectedItems.find((item) => item.Batch_ID === value.Batch_ID))
        return (
          <FormGroup className="mb-4" check>
            <Input
              type="checkbox"
              checked={!!selectedItems.find((item) => item.Batch_ID === value.Batch_ID)}
              onChange={(e) => {
                e.stopPropagation();
                handleCheckboxChange(value);
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </FormGroup>
        )
      },
    },
    {
      id:
        filtersData.end_date.includes("2020") ||
          filtersData.end_date.includes("2021")
          ? "TIMESTAMP"
          : "Timestamp",
      title: "End Time",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Line",
      title: "Line",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Recipe",
      title: "Recipe",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },

    {
      id: "Run_Time",
      title: "Runtime (Mins)",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Total_Packs",
      title: "Packs",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Avg_Speed",
      title: "Avg Speed",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },

    {
      id: "KPI",
      title: "KPI",
      className: "sort table-data-font",
      onClick: null,
      render: (record) => renderHtml("kpi", record),
    },
    {
      id: "Total_Wght",
      title: "Weight",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Operator_Speed",
      title: "Operator Speed",
      className: "sort table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Pack_Cost",
      title: "Cost/pack",
      className: "sort text-right table-data-font",
      onClick: null,
      render: (record) => renderHtml("Pack_Cost", record),
    },
    // {
    //   id: "action",
    //   title: "ACTION",
    //   className: "sort text-right",
    //   onClick: null,
    //   render: (record) => renderHtml("action", record),
    // },
  ];

  const premiumColumns = [
    {
      id: "Rcp_Product",
      title: "Product",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Rcp_Brand",
      title: "Brand",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Rcp_Tier",
      title: "Tier",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
  ];

  useEffect(() => {
    if (clientSubscription === "premium") {
      setTableColumns([...baseColumns, ...premiumColumns]);
    } else setTableColumns([...baseColumns]);
  }, [clientSubscription, selectedItems]);

  const renderHtml = (key, record) => {
    switch (key) {
      case "action":
        return (
          <>
            <i
              className="text-primary btn fa fa-edit px-1 py-0 m-0"
            />
          </>
        );

      case "status":
        return <>{record.status ? "Active" : "Inactive"}</>;

      case "Pack_Cost":
        return <>{record.Pack_Cost} £</>;

      case "kpi":
        return (
          <>
            <div className="d-flex align-items-center">
              <div>
                <Progress
                  className="textBg"
                  max="100"
                  value={record.KPI.toString()}
                  barClassName="bg-info"
                  style={{ height: "20px" }}
                >
                  <span className="progress-bg font-weight-bold">
                    {record.KPI}%
                  </span>
                </Progress>
              </div>
            </div>
          </>
        );

      default:
        break;
    }
  };

  const handleReport = async () => {
    try {
      console.log('selectedItems', selectedItems)
      setIsGeneratingReport1(true);
      const res = await axios.post(
        `${baseUrl}/api/reports/batches-data-report`,
        {
          batches: selectedItems.map(item => item.Batch_ID)
        },
        {
          params: { clientId },
          headers: authHeader(),

        }
      );
      window.open(res.data.url, "_blank");
    } catch (err) {
    } finally {
      setIsGeneratingReport1(false);
    }
  };

  const handleOperatorsReport = async () => {
    try {
      setIsGeneratingReport2(true);
      const res = await axios.get(
        `${baseUrl}/api/dashboard/operators-data-report`,
        {
          params: { ...filtersData, clientId },
          headers: authHeader(),
        }
      );
      window.open(res.data.url, "_blank");
    } catch (err) {
    } finally {
      setIsGeneratingReport2(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Row className="">
        <Col
          xs="12"
          className="mb-4"
        >
          <Card className="shadow h-100">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginRight: "2rem",
              }}
            >
              <h2 className="px-4 pt-4 text-color-mw">{title}</h2>
              {selectedItems.length > 0 && (
                <Button
                  style={{
                    backgroundColor: "#a7ce39",
                    border: "none",
                    height: "33px",
                    width: "fit-content",
                  }}
                  className="px-4"
                  size="sm"
                  onClick={handleReport}
                >
                  Get Report for Selected
                  <i className="fa fa-plus ml-2" />
                </Button>
              )}

            </div>
            <>
              <Table
                values={tableData}
                columns={tableColumns}
                tableClasses={{
                  table:
                    "table align-items-center table-flush table-sm border-bottom text-white table-custom",
                  thead: "",
                  tbody: "list table-td-color",
                }}
                isLoading={tableData === "Loading..."}
              />{" "}
              {tableData === "Loading..." ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 20,
                    marginRight: 20,
                  }}
                >
                  <Skeleton variant="rectangular" width={150} />
                  <Skeleton variant="rectangular" width={100} />
                  <Skeleton variant="rectangular" width={20} />
                  <Skeleton variant="rectangular" width={20} />
                </div>
              ) : (
                <TablePagination
                  component="div"
                  className="text-muted"
                  count={count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </>
            {tableData !== "Loading..." && tableData.length === 0 && (
              <span className="m-4">No Data Found</span>
            )}
          </Card>
        </Col>

      </Row>

    </>
  );
}

export default BatchesData;
