import { Skeleton } from "@mui/material";
import React from "react";
import { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { Card, CardBody, CardTitle, Col, Row, Tooltip } from "reactstrap";

function DataCardTemplate({ title, value, toolTipData, isLoading, cardImage }) {
  const [tooltip, setTooltip] = useState(false);

  return isLoading ? (
    <Card className="shadow-sm">
      <CardBody className="p-2">
        <div className="col">
          <CardTitle tag="h2" className="text-muted mb-0">
            {title}
            <FaInfoCircle
              id={Math.random().toString(36).substring(7)}
              fontSize={"18px"}
              className="ml-2"
            />
          </CardTitle>
          <span className="h2 font-weight-bold text-white mb-0">
            <Skeleton variant="text" width={50} />
          </span>
        </div>
      </CardBody>
    </Card>
  ) : (
    <Card className="shadow-sm">
      <CardBody>
        <Row>
          <Col>
            <CardTitle tag="h2" className="text-muted mb-0">
              {title}
              <FaInfoCircle
                id={toolTipData.id}
                fontSize={"18px"}
                className="ml-2"
              />
              <Tooltip
                placement={"top"}
                isOpen={tooltip}
                target={toolTipData.id}
                toggle={() => {
                  setTooltip((st) => !st);
                }}
              >
                {toolTipData.message}
              </Tooltip>
            </CardTitle>
            <span className="h2 font-weight-bold text-white mb-0">{value}</span>
          </Col>
          <Col className="col-auto">
            <div
              className="icon icon-shape text-white rounded-circle shadow"
              style={{
                backgroundColor: "#731e2f",
                width: "70px",
                height: "70px",
              }}
            >
              <img src={cardImage} width={40} alt="" />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default DataCardTemplate;
