import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, CardHeader, Row, Spinner } from "reactstrap";
import Table from "views/shared/Table";
import { TablePagination } from "@material-ui/core";
import axios from "axios";
import { baseUrl } from "../../constants/url";
import authHeader from "../../services/auth-header";
import { Skeleton } from "@mui/material";
import DetailedDataModal from "./DetailedDataModal/DetailedDataModal";

function GiveawayTableData({
  filtersData,
  clientId,
  timeRange,
  clientSubscription,
}) {
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [isLoadingTableData, setIsLoadingTableData] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = useState(0);
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [detailedDataModal, setDetailedDataModal] = useState({
    open: false,
    modalData: null,
  });

  useEffect(() => {
    setIsLoadingTableData(true);
    axios
      .get(`${baseUrl}/api/gaAnalysis/tableData/${page}/${rowsPerPage}`, {
        params: { ...filtersData, clientId },
        headers: authHeader(),
      })
      .then((res) => {
        // setTableData(res.data.data);
        setTableData(res.data.tableRows);
        setCount(res.data.count);
        setIsLoadingTableData(false);
      })
      .catch((err) => {
        console.log(err);
        setTableData([]);
        setIsLoadingTableData(false);
      });
  }, [page, rowsPerPage, clientId, filtersData, timeRange]);

  const baseColumns = [
    {
      id: "day",
      title: "Date",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Line",
      title: "Line",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "Recipe",
      title: "Recipe",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "gaPercentage",
      title: "GA Percentage",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: (record) => (
        <>
          {((record.total_ga / (record.total_tonnage * 1000)) * 100).toFixed(2)}
        </>
      ),
    },
    {
      id: "total_ga",
      title: "Total GA (Kg)",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: (record) => <>{(record.total_ga / 1000).toFixed(2)}</>,
    },
    {
      id: "total_tonnage",
      title: "Total Weight (Kg)",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "total_packs",
      title: "Total Packs",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "total_runtime",
      title: "Total Runtime",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
  ];

  const premiumColumns = [
    {
      id: "product",
      title: "Product",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "brand",
      title: "Brand",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
    {
      id: "tier",
      title: "Tier",
      className: "sort custom-padding table-data-font",
      onClick: null,
      render: null,
    },
  ];

  useEffect(() => {
    if (clientSubscription === "premium") {
      setTableColumns([...baseColumns, ...premiumColumns]);
    } else setTableColumns([...baseColumns]);
  }, [clientSubscription]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleGiveawayReport = async () => {
    try {
      setIsGeneratingReport(true);
      const res = await axios.get(`${baseUrl}/api/gaAnalysis/exportData`, {
        params: { ...filtersData, clientId },
        headers: authHeader(),
      });
      window.open(res.data.url, "_blank");
    } catch (err) {
    } finally {
      setIsGeneratingReport(false);
    }
  };

  return (
    <Card className="card-stats h-100">
      <CardHeader className="bg-transparent">
        <Row className=" px-3 align-items-center d-flex justify-content-between">
          <div>
            <h2 className="mb-0 text-color-mw">GiveAway Analysis</h2>
          </div>
          <div>
            <Button
              style={{
                backgroundColor: "#a7ce39",
                border: "none",
                height: "33px",
                width: "fit-content",
                marginLeft: "auto",
              }}
              className="px-4"
              size="sm"
              onClick={handleGiveawayReport}
              disabled={isGeneratingReport}
            >
              {isGeneratingReport ? (
                <Spinner size="sm" />
              ) : (
                <>
                  {" "}
                  Giveaway Export
                  <i className="fa fa-plus ml-2" />
                </>
              )}
            </Button>
          </div>
        </Row>
      </CardHeader>
      <CardBody className="text-muted h-100 p-2">
        <Table
          values={tableData}
          columns={tableColumns}
          onRowClick={(record) => {
            setDetailedDataModal({
              open: true,
              modalData: record,
            });
          }}
          tableClasses={{
            table:
              "table align-items-center table-flush table-sm border-bottom text-white table-custom",
            thead: "text-muted",
            tbody: "list",
          }}
          isLoading={isLoadingTableData}
        />

        {isLoadingTableData ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 20,
              marginRight: 20,
            }}
          >
            <Skeleton variant="rectangular" width={150} />
            <Skeleton variant="rectangular" width={100} />
            <Skeleton variant="rectangular" width={20} />
            <Skeleton variant="rectangular" width={20} />
          </div>
        ) : (
          <TablePagination
            component="div"
            className="text-muted"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </CardBody>
      {detailedDataModal.open && (
        <DetailedDataModal
          isOpen={detailedDataModal.open}
          onClose={() =>
            setDetailedDataModal({
              open: false,
              modalData: null,
            })
          }
          modalData={detailedDataModal.modalData}
          clientId={clientId}
          clientSubscription={clientSubscription}
        />
      )}
    </Card>
  );
}

export default GiveawayTableData;
