import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";

import {
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
} from "chart.js";

import Chart from "chart.js/auto";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-moment";
import { useAuth } from "contexts/AuthContext";
import { particleOptions } from "constants/particleOptions";
import Particles from "react-tsparticles";

Chart.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  zoomPlugin
);

function App() {
  const { authenticating, user } = useAuth();

  if (authenticating)
    return <Particles id="tsparticles" options={particleOptions} />;

  return (
    <BrowserRouter>
      {user ? (
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Redirect from="/" to="/admin/index" />
        </Switch>
      ) : (
        <Switch>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="/" to="/auth/login" />
        </Switch>
      )}
    </BrowserRouter>
  );
}

export default App;
