import React from "react";
import PropTypes from "prop-types";
import { CardBody } from "reactstrap";
import { Skeleton } from "@mui/material";

const renderValue = (col, record, index) => {
  if (col.render) {
    return col.render(record, index);
  } else if (col.id === "status")
    if (record["is_deleted"] === true) return "Deleted";
    else if (record["is_active"] === true) return "Enabled";
    else return "Disabled";
  else return record[col.id];
};
function Table(props) {
  const { isLoading, handleSelected, selected = [] } = props;

  return isLoading ? <CardBody className="py-0">
    <div className="table-responsive my-4">
      <table className={props.tableClasses.table}>
        {props.noHeader ? null : (
          <thead className={props.tableClasses.thead}>
            <tr className={props.tableClasses.tr}>
              {props.columns.map((item) => {
                return (
                  <th
                    key={item.id}
                    className={item.className}
                    onClick={item.onHeadingClick ? item.onHeadingClick : null}
                    style={{ fontSize: "16px" }}
                  >
                    <Skeleton width={100} height={30} />
                  </th>
                );
              })}
            </tr>
          </thead>
        )}
        <tbody className={props.tableClasses.tbody}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((record, index) => (
            <tr
              key={index}
              className={
                props.rowClassName
                  ? props.rowClassName
                  : record.changed
                    ? record.changed + " font-weight-bolder text-dark"
                    : ""
              }
              style={{
                backgroundColor: record.is_deleted ? "#ff000038" :
                  record.is_active === false ? "rgb(255 255 255 / 22%)" : ""
              }}
            >
              {props.columns.map((col) => (
                <td
                  key={col.id}
                  className={col.className}
                  onClick={() =>
                    col.onClick && !props.onRowClick
                      ? col.onClick(record)
                      : null
                  }
                >
                  <Skeleton width={100} height={30} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </CardBody> : <CardBody className="py-0">
    <div className="table-responsive my-4">
      <table className={props.tableClasses.table}>
        {props.noHeader ? null : (
          <thead className={props.tableClasses.thead}>
            <tr className={props.tableClasses.tr}>
              {props.columns.map((item) => {
                return (
                  <th
                    key={item.id}
                    className={item.className}
                    onClick={item.onHeadingClick ? item.onHeadingClick : null}
                    style={{ fontSize: "16px" }}
                  >
                    {item.title}
                  </th>
                );
              })}
            </tr>
          </thead>
        )}
        <tbody className={props.tableClasses.tbody}>
          {props.values.map((record, index) => (
            <tr
              key={index}
              className={
                props.rowClassName
                  ? props.rowClassName
                  : record.changed
                    ? record.changed + " font-weight-bolder text-dark"
                    : ""
              }
              onClick={(e) => {
                e.stopPropagation();
                if (props.onRowClick) props.onRowClick(record)
              }
              }
              style={{
                backgroundColor: record.is_deleted ? "#ff000038" :
                  record.is_active === false ? "rgb(255 255 255 / 22%)" :
                    // handleSelected && (!!selected.find(el => el.id === record.id)) ? "black" : "",
                    handleSelected && selected.includes(record.id) ? "black" : "",
              }}
            >
              {props.columns.map((col) => (
                <td
                  key={col.id}
                  className={col.className}
                  onClick={() =>
                    col.onClick && !props.onRowClick
                      ? col.onClick(record)
                      : null
                  }
                >
                  {renderValue(col, record, index)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </CardBody>
}

Table.propTypes = {
  columns: PropTypes.array,
  values: PropTypes.array,
  rowClassName: PropTypes.string,
  onRowClick: PropTypes.func,
  tableClasses: PropTypes.object,
  noHeader: PropTypes.bool,
  isLoading: PropTypes.bool,
};

Table.defaultProps = {
  columns: [
    {
      id: "test",
      title: "test",
      className: "",
      onClick: null,
      render: null,
    },
  ],
  values: [
    {
      test: "test",
    },
  ],
  rowClassName: "",
  onRowClick: null,
  tableClasses: {
    table: "",
    thead: "",
    tr: "",
  },
  noHeader: false,
  isLoading: false,
};

export default Table;
