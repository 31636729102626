import React, { useState } from "react";
import { TbLayoutSidebarRightCollapseFilled } from "react-icons/tb";
import logo from "../../assets/img/brand/MiWeigh.png";
import "./Sidebar.css";
import ManagementPanelImage from "../../assets/img/icons/management_panel_icon.png";
import ManagementPanelImageGreen from "../../assets/img/icons/management_panel_icon_green.png";
import ViewDataImage from "../../assets/img/icons/view_data_icon.png";
import ViewDataImageGreen from "../../assets/img/icons/view_data_icon_green.png";
import AnalyticsImage from "../../assets/img/icons/Miweigh_Analytics_icon_balanced.png";
import AnalyticsImageGreen from "../../assets/img/icons/Miweigh_Analytics_icon_balanced_green.png";
import CurrentStatus from "../../assets/img/icons/Current_status_MW.png";
import CurrentStatusImageGreen from "../../assets/img/icons/Current_status_MW_Green.png";
import OperatorPerformance from "../../assets/img/icons/op.png";
import OperatorPerformanceGreen from "../../assets/img/icons/op_green.png";
// import Tutorials from "../../assets/img/icons/tutorials.png";
// import TutorialsGreen from "../../assets/img/icons/tutorials-green.png";
import Mining from "../../assets/img/icons/mw_mining.png";
import MiningGreen from "../../assets/img/icons/mw_mining_green.png";
import ReportImage from "../../assets/img/icons/management_panel_icon.png";
import ReportImageGreen from "../../assets/img/icons/management_panel_icon_green.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "contexts/AuthContext";

const Sidebar = ({ clientSubscription }) => {
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();
  const { user } = useAuth();

  const [mpImage, setMpImage] = useState(ManagementPanelImage);
  const [vwImage, setVwImage] = useState(ViewDataImage);
  const [abImage, setAbImage] = useState(AnalyticsImage);
  const [csImage, setCsImage] = useState(CurrentStatus);
  const [opImage, setOpImage] = useState(OperatorPerformance);
  // const [tutorialsImage, setTutorialsImage] = useState(Tutorials);
  const [miningImage, setMiningImage] = useState(Mining);
  const [gaAnalysisImage, setGaAnalysisImage] = useState(Mining);
  const [reportsImage, setReportsImage] = useState(ReportImage);

  return (
    <div
      className="sidebar-container"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {/* Icon Indicator */}
      <div className="sidebar-indicator">
        <TbLayoutSidebarRightCollapseFilled />
      </div>

      {/* Sidebar Content */}
      <div className={`sidebar ${isVisible ? "sidebar-visible" : ""}`}>
        <div className="mb-5 text-center">
          <img
            src={logo} // Replace with your image URL
            alt="Sidebar Logo"
            width={200}
          />
        </div>
        <ul className="sidebar-menu">
          {user.role === "user" || user.role === "engineer" ? null : (
            <li
              onClick={() => history.push("/admin/panel")}
              onMouseEnter={() => setMpImage(ManagementPanelImageGreen)}
              onMouseLeave={() => setMpImage(ManagementPanelImage)}
            >
              <div className="menu-item">
                <span className="menu-icon">
                  <img className="m-1 mr-3" src={mpImage} width={40} alt="" />
                </span>
                <span className="menu-text">Management Panel</span>
              </div>
            </li>
          )}

          <li
            onClick={() => history.push("/admin/view_data")}
            onMouseEnter={() => setVwImage(ViewDataImageGreen)}
            onMouseLeave={() => setVwImage(ViewDataImage)}
          >
            <div className="menu-item">
              <span className="menu-icon">
                {" "}
                <img className="m-1 mr-3" src={vwImage} width={40} alt="" />
              </span>
              <span className="menu-text">Dashboard</span>
            </div>
          </li>
          <li
            onClick={() => history.push("/admin/analytics")}
            onMouseEnter={() => setAbImage(AnalyticsImageGreen)}
            onMouseLeave={() => setAbImage(AnalyticsImage)}
          >
            <div className="menu-item">
              <span className="menu-icon">
                <img className="m-1 mr-3" src={abImage} width={40} alt="" />
              </span>
              <span className="menu-text">Analytics</span>
            </div>
          </li>
          <li
            onClick={() => history.push("/admin/inProgress")}
            onMouseEnter={() => setCsImage(CurrentStatusImageGreen)}
            onMouseLeave={() => setCsImage(CurrentStatus)}
          >
            <div className="menu-item">
              <span className="menu-icon">
                <img className="m-1 mr-3" src={csImage} width={40} alt="" />
              </span>
              <span className="menu-text">Current Status</span>
            </div>
          </li>
          <li
            onClick={() => history.push("/admin/operatorPerformance")}
            onMouseEnter={() => setOpImage(OperatorPerformanceGreen)}
            onMouseLeave={() => setOpImage(OperatorPerformance)}
          >
            <div className="menu-item">
              <span className="menu-icon">
                {" "}
                <img className="m-1 mr-3" src={opImage} width={40} alt="" />
              </span>
              <span className="menu-text">Operator Performance</span>
            </div>
          </li>
          {/* <li
            onClick={() => history.push("/admin/tutorials")}
            onMouseEnter={() => setTutorialsImage(TutorialsGreen)}
            onMouseLeave={() => setTutorialsImage(Tutorials)}
          >
            <div className="menu-item">
              <span className="menu-icon">
                <img
                  className="m-1 mr-3"
                  src={tutorialsImage}
                  width={40}
                  alt=""
                />
              </span>
              <span className="menu-text">Tutorials</span>
            </div>
          </li> */}
          <li
            onClick={() => history.push("/admin/ga_analysis")}
            onMouseEnter={() => setGaAnalysisImage(MiningGreen)}
            onMouseLeave={() => setGaAnalysisImage(Mining)}
          >
            <div className="menu-item">
              <span className="menu-icon">
                <img
                  className="m-1 mr-3"
                  src={gaAnalysisImage}
                  width={40}
                  alt=""
                />
              </span>
              <span className="menu-text">Giveaway Analysis</span>
            </div>
          </li>
          {clientSubscription === "premium" && (
            <li
              onClick={() => history.push("/admin/mining")}
              onMouseEnter={() => setMiningImage(MiningGreen)}
              onMouseLeave={() => setMiningImage(Mining)}
            >
              <div className="menu-item">
                <span className="menu-icon">
                  <img
                    className="m-1 mr-3"
                    src={miningImage}
                    width={40}
                    alt=""
                  />
                </span>
                <span className="menu-text">Mining</span>
              </div>
            </li>
          )}
          <li
            onClick={() => history.push("/admin/reports")}
            onMouseEnter={() => setReportsImage(ReportImageGreen)}
            onMouseLeave={() => setReportsImage(ReportImage)}
          >
            <div className="menu-item">
              <span className="menu-icon">
                <img
                  className="m-1 mr-3"
                  src={reportsImage}
                  width={40}
                  alt=""
                />
              </span>
              <span className="menu-text">Reports</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
