import React, { useState, useEffect, useRef, useContext } from "react";
import AnalyticsComponent from "./AnalyticsComponent";
import {
  Container,
  Row,
  Col,
  Spinner,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import moment from "moment";
import TopValuesBar from "./TopValuesBar";
import ReactToPrint from "react-to-print";
import OverviewFlow from "./DecisionTree/DecisionTree";
import Filters from "./FiltersAnalyticsBoard";
import { DataContext } from "layouts/Admin";


function getPreviousDate(start, end) {
  let durationDays = moment(end).diff(moment(start), "days");
  const p_start_date = moment(start)
    .subtract(durationDays, "day")
    .toISOString();
  const p_end_date = moment(end).subtract(durationDays, "day").toISOString();
  return { p_end_date, p_start_date };
}

function AnalyticsBoard({ clientId }) {
  const componentRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingOpPpm, setIsLoadingOpPpm] = useState(true);
  const [data, setData] = useState({});
  const [dataOpPpm, setDataOpPpm] = useState({});
  const { filtersData, handleApplyFilters } = useContext(DataContext)
  const [p_duration, setP_duration] = useState({
    ...getPreviousDate(filtersData.start_date, filtersData.end_date),
  });

  const handleFilters = (filterData) => {
    handleApplyFilters(filterData);
    setP_duration({
      ...getPreviousDate(filterData.start_date, filterData.end_date),
    });
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${baseUrl}/api/analytics`, {
        params: {
          clientId,
          start_date: filtersData.start_date,
          end_date: filtersData.end_date,
        },
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res);
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setData({});
        setIsLoading(false);
      });
  }, [clientId, filtersData.start_date, filtersData.end_date]);

  useEffect(() => {
    setIsLoadingOpPpm(true);
    axios
      .get(`${baseUrl}/api/analytics/Scale`, {
        params: {
          clientId,
          start_date: filtersData.start_date,
          end_date: filtersData.end_date,
        },
        headers: authHeader(),
      })
      .then((res) => {
        setDataOpPpm(res.data);
        setIsLoadingOpPpm(false);
      })
      .catch((err) => {
        console.log(err);
        setDataOpPpm({});
        setIsLoadingOpPpm(false);
      });
  }, [clientId, filtersData.start_date, filtersData.end_date]);

  return (
    <div className="header bg-transparent py-0 mt-4" ref={componentRef}>
      <Container fluid>
        <div className="header-body">
          <Row className="mb-2">
            <Col xs="12" md="3" className="mb-2" id="no-print">
              {/* <ReactToPrint
                trigger={() => (
                  <button
                    className="btn"
                    style={{
                      backgroundColor: "#a7ce39",
                      border: "none",
                      color: "black",
                      // minWidth: "200px",
                    }}
                  >
                    Get Report
                    <a
                        // className="btn"
                        href={Report}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          backgroundColor: "#a7ce39",
                          border: "none",
                          color: "black",
                        }}
                      >
                        Generate PDF
                      </a>
                  </button>
                )}
                content={() => componentRef.current}
              /> */}
              {/* <button
                className="btn"
                style={{
                  backgroundColor: "#a7ce39",
                  border: "none",
                  color: "black",
                  // minWidth: "200px",
                }}
              >
                <a
                  // className="btn"
                  href={Report}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    backgroundColor: "#a7ce39",
                    border: "none",
                    color: "black",
                  }}
                >
                  Generate PDF
                </a>
              </button> */}
            </Col>
            <Col
              xs="12"
              md="5"
              className="text-center mb-2"
              id="page-top-margin"
            >
              <span className="h1" style={{ color: "#a7ce39" }}>
                Mi Weigh Analytics
              </span>
            </Col>
            <Col xs="12" md="4" className="text-right mb-2">
              <div className="h3 text-muted">
                <span style={{ color: "#a7ce39" }}>Current: </span>
                {new Date(filtersData.start_date).toDateString() +
                  " - " +
                  new Date(filtersData.end_date).toDateString()}
              </div>
              <div className="h3 text-muted">
                <span style={{ color: "#a7ce39" }}>Previous: </span>
                {new Date(p_duration.p_start_date).toDateString() +
                  " - " +
                  new Date(p_duration.p_end_date).toDateString()}
              </div>
            </Col>
            <Col xs="12" className="text-left mb-4">
              <Filters
                filtersData={filtersData}
                handleApplyFilters={handleFilters}
              />
              {/* <span className="h1" style={{ color: "#a7ce39" }}>
                Duration
              </span>
              <span className="h2 text-muted ml-5"> */}
              {/* {yesterday.toDateString()} */}
              {/* {filtersData.start_date.replace('T', " ---- ")} */}
              {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
              {/* {today.toDateString()} */}
              {/* {filtersData.end_date.replace('T', " ---- ")} */}
              {/* </span> */}
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="6" xl="4">
              <AnalyticsComponent
                isLoading={isLoading}
                title="Labour Cost/Pack"
                key="labourCost"
                barData={{
                  current: data.resData ? data.resData.current.dataAll.lbrCost : [],
                  previous: data.resData ? data.resData.previous.dataAll.lbrCost : [],
                }}
                lineChartData={{
                  current: data.resData ? data.resData.current.dataByDay.lbrCost : [],
                  previous: data.resData ? data.resData.previous.dataByDay.lbrCost : [],
                }}
              />
            </Col>
            <Col xs="12" md="6" xl="4">
              <AnalyticsComponent
                isLoading={isLoading}
                title="Average Speed"
                key="averageSpeed"
                barData={{
                  current: data.resData ? data.resData.current.dataAll.avgSpeed : [],
                  previous: data.resData ? data.resData.previous.dataAll.avgSpeed : [],
                }}
                lineChartData={{
                  current: data.resData ? data.resData.current.dataByDay.avgSpeed : [],
                  previous: data.resData ? data.resData.previous.dataByDay.avgSpeed : [],
                }}
              />
            </Col>
            <Col xs="12" md="6" xl="4" id="page-margin-top-1">
              <AnalyticsComponent
                isLoading={isLoading}
                title="Average KPI"
                key="averageKpi"
                barData={{
                  current: data.resData ? data.resData.current.dataAll.avgKpi : [],
                  previous: data.resData ? data.resData.previous.dataAll.avgKpi : [],
                }}
                lineChartData={{
                  current: data.resData ? data.resData.current.dataByDay.avgKpi : [],
                  previous: data.resData ? data.resData.previous.dataByDay.avgKpi : [],
                }}
              />
            </Col>
            <Col xs="12" md="6" xl="4" id="page-margin-bottom-1">
              <AnalyticsComponent
                isLoading={isLoading}
                title="Average Give away"
                key="avergaeGiveaway"
                barData={{
                  current: data.resData ? data.resData.current.dataAll.avgGa : [],
                  previous: data.resData ? data.resData.previous.dataAll.avgGa : [],
                }}
                lineChartData={{
                  current: data.resData ? data.resData.current.dataByDay.avgGa : [],
                  previous: data.resData ? data.resData.previous.dataByDay.avgGa : [],
                }}
              />
            </Col>
            <Col xs="12" md="6" xl="4" id="page-margin-top-1">
              <AnalyticsComponent
                isLoading={isLoading}
                title="Number of Packs Produced"
                key="packsProduced"
                barData={{
                  current: data.resData ? data.resData.current.dataAll.packs : [],
                  previous: data.resData ? data.resData.previous.dataAll.packs : [],
                }}
                lineChartData={{
                  current: data.resData ? data.resData.current.dataByDay.packs : [],
                  previous: data.resData ? data.resData.previous.dataByDay.packs : [],
                }}
              />
            </Col>
            <Col xs="12" md="6" xl="4" id="page-margin-bottom-1">
              <AnalyticsComponent
                isLoading={isLoading}
                title="Total Run time"
                key="runTime"
                barData={{
                  current: data.resData ? data.resData.current.dataAll.runTime : [],
                  previous: data.resData ? data.resData.previous.dataAll.runTime : [],
                }}
                lineChartData={{
                  current: data.resData ? data.resData.current.dataByDay.runTime : [],
                  previous: data.resData ? data.resData.previous.dataByDay.runTime : [],
                }}
              />
            </Col>
            <Col xs="12" md="4" id="page-margin-top-1">
              <TopValuesBar
                isLoading={isLoading}
                title="Recipes with Most Give Away"
                key="topRecipes"
                topValues={data.topRecipes}
                color="rgba(255,153,51,0.6)"
                yaxisTitle="Average GA (g)"
              />
            </Col>
            <Col xs="12" md="4" id="page-margin-bottom-1">
              <TopValuesBar
                isLoading={isLoading}
                title="Top Line Leaders"
                key="topOperators"
                topValues={data.operatorKpi}
                color="rgba(55,128,191,0.6)"
                yaxisTitle="KPI (%)"
              />
            </Col>
            <Col xs="12" md="4" id="page-margin-top-2">
              <TopValuesBar
                isLoading={isLoading}
                title="Top Operators"
                key="topOperators"
                topValues={dataOpPpm.topOperators}
                color="#a7ce39"
                yaxisTitle="Average PPM"
              />
            </Col>
          </Row>

        </div>
        <Row>
          <Col xs="12" id="no-print">
            <Card className="shadow w-100 mb-4">
              <CardHeader className="bg-transparent">
                <h2 className="mb-0 text-color-mw">Decision Tree</h2>
              </CardHeader>
              <CardBody
                className="p-0"
                style={{ width: "100%", height: "700px" }}
              >
                <OverviewFlow />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AnalyticsBoard;
