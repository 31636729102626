import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Nav,
  NavItem,
  Input,
  Tooltip,
} from "reactstrap";
import Plot from "react-plotly.js";
import axios from "axios";
import { baseUrl } from "constants/url";
import authHeader from "services/auth-header";
import moment from "moment";
import deepEqual from "utils/deepequal";
import { FaInfoCircle } from "react-icons/fa";
import { Skeleton } from "@mui/material";

function RecipeChartGA({ clientId, filtersData, timeRange }) {
  const [chartData, setChartData] = useState({ x: [], y: [], hoverText: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({ ...filtersData });
  const [duration, setDuration] = useState("");
  const [tooltip, setTooltip] = useState(false);

  const onChange = (event) => {
    const value = event.target.value;
    setDuration(value);
    if (value === "") {
      setFilter({
        ...filtersData,
      });
    } else if (value === "Last Week") {
      const start = moment().subtract(1, "weeks").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      setFilter((prevState) => {
        return {
          ...prevState,
          start_date: start,
          end_date: end,
        };
      });
    } else if (value === "Last Month") {
      const start = moment().subtract(1, "months").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      setFilter((prevState) => {
        return {
          ...prevState,
          start_date: start,
          end_date: end,
        };
      });
    } else if (value === "Last Year") {
      const start = moment().subtract(1, "years").utcOffset(0, true).format();
      const end = moment().utcOffset(0, true).format();
      setFilter((prevState) => {
        return {
          ...prevState,
          start_date: start,
          end_date: end,
        };
      });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setChartData({});
    axios
      .get(`${baseUrl}/api/gaAnalysis/chartsData`, {
        params: { ...filter, clientId, chartKey: "recipe" },
        headers: authHeader(),
      })
      .then((res) => {
        let x = [];
        let y = [];
        let hoverText = [];
        res.data.responseObject.recipes.forEach((item) => {
          x.push(item.x);
          y.push(item.y);
          hoverText.push(item.x);
        });
        setChartData({ x, y, hoverText });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setChartData({});
      });
  }, [filter, clientId]);

  useEffect(() => {
    setDuration("");
    if (deepEqual(filtersData, filter)) return;
    setFilter({ ...filtersData });
  }, [filtersData, clientId]);

  const formattedChartData = [
    {
      x: chartData.x, // Ensure chartLabels is defined and an array
      y: chartData.y, // Access the corresponding data for each key
      type: "bar",
      yaxis: "y2",
      mode: "lines",
      text: chartData.hoverText,
      name: "GA percentage",
      marker: { color: "rgba(255,153,51,0.6)" },
      // width: 0.3,
      hovertemplate: "<b>%{text}</b>: %{y}<extra></extra>",
    },
  ];

  const chartOptions = {
    showlegend: true,
    autosize: true,
    //title: 'KPI',
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    yaxis: { title: "Daily GA percentage" },
    xaxis: {
      tickvals: chartData.x || [], // Ensure tickvals are set only when data is available
      ticktext: (chartData.x || []).map((label) =>
        label.length > 10 ? label.substring(0, 10) + "..." : label
      ),
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true
  };

  return isLoading ? (
    <Card
      className="h-100 card-stats"
      style={{
        paddingBottom: "30px",
      }}
    >
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">
              <Skeleton width={100} height={70} />
            </h2>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              <NavItem>
                <Skeleton width={100} height={70} />
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody
        className="p-0"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Skeleton variant="rectangular" width={"80%"} height={350} />
      </CardBody>
    </Card>
  ) : (
    <Card className="h-100 card-stats">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">
              Recipe
              <FaInfoCircle
                id="recipe-chart-tooltip"
                fontSize={"18px"}
                className="ml-2"
              />
              <Tooltip
                placement={"top"}
                isOpen={tooltip}
                target={`recipe-chart-tooltip`}
                toggle={() => {
                  setTooltip((st) => !st);
                }}
              >
                Graph of daily GA percentage against Recipes (TOP 5)
              </Tooltip>
            </h2>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              <NavItem>
                <Input
                  placeholder="Duration"
                  name="country_id"
                  type="select"
                  value={duration}
                  className="textBg"
                  bsSize="sm"
                  style={{ color: "#000000" }}
                  onChange={onChange}
                >
                  {["", "Last Week", "Last Month", "Last Year"].map(
                    (option, idx) => {
                      return (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      );
                    }
                  )}
                </Input>
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody className="p-0">
        {isLoading ? (
          <div className="m-5">Loading...</div>
        ) : Object.keys(chartData).length > 0 ? (
          <div className="chart" style={{ height: "auto" }}>
            <div className="chart-container-bar">
              {chartData && (
                <Plot
                  data={formattedChartData}
                  className="w-100 h-100"
                  useResizeHandler
                  layout={chartOptions}
                  config={config}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="m-5">No Data Found</div>
        )}
      </CardBody>
    </Card>
  );
}

export default RecipeChartGA;
