import React, { useContext } from "react";
import Filters from "views/shared/Filters";
import { Container } from "reactstrap";
import { DataContext } from "layouts/Admin.js";
import OperatorsData from "./OperatorsData";
import DataTable from "views/TableContainer/index.js";
import LinesData from "./LinesData";

function Index({ clientId, clientSubscription }) {
  const { filtersData, setFiltersData, timeRange, setTimeRange } =
    useContext(DataContext);

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 7);

  const handleApplyFilters = (filterData) => {
    setFiltersData({ ...filterData });
  };

  return (
    <Container className="bg-transparent" fluid>
      <Filters
        filtersData={filtersData}
        handleApplyFilters={handleApplyFilters}
        clientId={clientId}
        clientSubscription={clientSubscription}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
      />
      <div className="mt-4" />
      <Container fluid>
        <DataTable
          filtersData={filtersData}
          setFiltersData={setFiltersData}
          clientId={clientId}
          title="Batches Data"
        />
      </Container>
      <div className="mt-4" />
      <OperatorsData
        filtersData={filtersData}
        setFiltersData={setFiltersData}
        clientId={clientId}
        timeRange={timeRange}
        type="Operator"
        title="Operators Data"
      />
      <div className="mt-4" />
      <LinesData
        filtersData={filtersData}
        setFiltersData={setFiltersData}
        clientId={clientId}
        timeRange={timeRange}
        title="Lines Data"
      />
      {/* <OperatorsData
        filtersData={filtersData}
        setFiltersData={setFiltersData}
        clientId={clientId}
        timeRange={timeRange}
        type='Line'
        title="Lines Data"
      /> */}
    </Container>
  );
}

export default Index;
