import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Nav,
  NavItem,
  Tooltip,
} from "reactstrap";
import Plot from "react-plotly.js";
import { FaInfoCircle } from "react-icons/fa";
import { Skeleton } from "@mui/material";

function BarChartsGiveawayPercentage({ data, tooltipData, title }) {
  const [chartData, setChartData] = useState({ x: [], y: [], hoverText: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [tooltip, setTooltip] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setChartData({});
    try {
      setChartData({ x: data.x, y: data.y, hoverText: data.x });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setChartData({});
    }
  }, [data]);

  const formattedChartData = [
    {
      x: chartData.x, // Ensure chartLabels is defined and an array
      y: chartData.y, // Access the corresponding data for each key
      type: "bar",
      yaxis: "y2",
      mode: "lines",
      text: chartData.hoverText,
      name: "GA percentage",
      marker: { color: "rgba(255,153,51,0.6)" },
      // width: 0.3,
      hovertemplate: "<b>%{text}</b>: %{y}<extra></extra>",
    },
  ];

  const chartOptions = {
    showlegend: true,
    autosize: true,
    //title: 'KPI',
    legend: {
      orientation: "h",
      yanchor: "bottom",
      y: 1.02,
      xanchor: "right",
      x: 1,
    },
    yaxis: { title: "Daily GA percentage" },
    xaxis: {
      tickvals: chartData.x || [], // Ensure tickvals are set only when data is available
      ticktext: (chartData.x || []).map((label) =>
        label.length > 10 ? label.substring(0, 10) + "..." : label
      ),
    },
    paper_bgcolor: "rgb(36,41,40)",
    plot_bgcolor: "rgb(36,41,40)",
    font: {
      family: "Open Sans, sans-serif",
      size: 13,
      color: "white",
    },
  };

  const config = {
    displayModeBar: false, // this is the line that hides the bar.
    // responsive: true
  };

  return isLoading ? (
    <Card
      className="h-100 card-stats"
      style={{
        paddingBottom: "30px",
      }}
    >
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">
              <Skeleton width={100} height={70} />
            </h2>
          </div>
          <div className="col">
            <Nav className="justify-content-end" pills>
              <NavItem>
                <Skeleton width={100} height={70} />
              </NavItem>
            </Nav>
          </div>
        </Row>
      </CardHeader>
      <CardBody
        className="p-0"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Skeleton variant="rectangular" width={"80%"} height={350} />
      </CardBody>
    </Card>
  ) : (
    <Card className="h-100 card-stats mb-4">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h2 className="mb-0 text-color-mw">
              {title}
              <FaInfoCircle
                id={tooltipData.id}
                fontSize={"18px"}
                className="ml-2"
              />
              <Tooltip
                placement={"top"}
                isOpen={tooltip}
                target={tooltipData.id}
                toggle={() => {
                  setTooltip((st) => !st);
                }}
              >
                {tooltipData.message}
              </Tooltip>
            </h2>
          </div>
        </Row>
      </CardHeader>
      <CardBody className="p-0">
        {isLoading ? (
          <div className="m-5">Loading...</div>
        ) : Object.keys(chartData).length > 0 ? (
          <div className="chart" style={{ height: "auto" }}>
            <div className="chart-container-bar">
              {chartData && (
                <Plot
                  data={formattedChartData}
                  className="w-100 h-100"
                  useResizeHandler
                  layout={chartOptions}
                  config={config}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="m-5">No Data Found</div>
        )}
      </CardBody>
    </Card>
  );
}

export default BarChartsGiveawayPercentage;
